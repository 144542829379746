import React, { useEffect, useState } from "react";
import Tick from "../assets/tick.png";
import Arrow from "../assets/arrow.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
function PaymentSuccessPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const trans_id = searchParams.get("trans_id");

    console.log("trans_id", trans_id);

    const [paymentInprogress] = useState(
        localStorage.getItem("paymentInProgress")
    );

    const [paymentType] = useState(localStorage.getItem("paymentType"));

    const [mobile] = useState(localStorage.getItem("mobile"));

    const [email] = useState(localStorage.getItem("email"));

    const [amount] = useState(localStorage.getItem("amount"));
    const [acNo] = useState(localStorage.getItem("acNo"));
    const [outstandingBalance] = useState(
        localStorage.getItem("outstandingBalance")
    );

    // ............ send success mail.........

    const sendSuccessMail = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}payment-gateway/send-sucess-mail?email=${email}&amount=${amount}&acNo=${acNo}&transcactionId=${trans_id}&phone=${mobile}&outstandingBalance=${outstandingBalance}`
            );
        } catch (error) {
            console.error("Error fetching customer details:", error);
        } finally {
            // setLoading(false);
        }
    };

    // ....... to scroll top .....

    useEffect(() => {
        window.scrollTo({ top: 80, behavior: "smooth" });
    }, []);

    // ......... Sending the mail ............

    useEffect(() => {
        email && acNo && amount && trans_id && sendSuccessMail();
    }, []);

    //............. move to payment page when refresh the page.....

    useEffect(() => {
        if (paymentInprogress === "true") {
            localStorage.removeItem("paymentInProgress");
            localStorage.removeItem("paymentType");
            localStorage.removeItem("mobile");
            localStorage.removeItem("email");
            localStorage.removeItem("amount");
            localStorage.removeItem("acNo");
            localStorage.removeItem("outstandingBalance");
            localStorage.removeItem("encryptData");
        } else {
            navigate("/");
            console.log("i am not working");
        }
    }, []);

    return (
        <div>
            <section className="pay-success">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="div1 text-center">
                                <img
                                    src={Tick}
                                    className="img-fluid mb-4"
                                    alt="Success Tick"
                                />
                                <h4>Payment Successful</h4>

                                <table
                                    width="60%"
                                    className="mx-auto pay-success-table"
                                >
                                    <tbody>
                                        <tr>
                                            <td>Payment Type</td>
                                            <td className="text-end">
                                                {paymentType}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Mobile</td>
                                            <td className="text-end">
                                                {mobile.length > 3
                                                    ? mobile
                                                    : ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td className="text-end">
                                                {email}
                                            </td>
                                        </tr>
                                        <tr height="50px">
                                            <td>
                                                <b>Amount Paid</b>
                                            </td>
                                            <td className="text-end">
                                                <b>AED {amount}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Id</td>
                                            <td className="text-end">
                                                {trans_id}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <a href="https://www.uticome.com/">
                                    <img
                                        src={Arrow}
                                        className="img-fluid"
                                        alt="Back to Home Arrow"
                                    />{" "}
                                    Back To Home
                                </a>
                                {/* <a href="/">
                                    <img
                                        src={Arrow}
                                        className="img-fluid"
                                        alt="Back to Home Arrow"
                                    />{" "}
                                    Back To Payment Page
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Scripts */}
            <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.3/dist/umd/popper.min.js"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        </div>
    );
}

export default PaymentSuccessPage;
