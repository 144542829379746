import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import "../App.css"; // Import custom CSS
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { htmlToText } from "html-to-text";

const TextEditor = () => {
    const navigate = useNavigate();

    const [editorContent, setEditorContent] = useState("");

    const handleEditorChange = (value) => {
        setEditorContent(value);
    };

    const onSave = async (e) => {
        e.preventDefault();
        const updatedContent = htmlToText(editorContent);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}payment-gateway/write-file`,
                { data: updatedContent }
            );
            console.log("first", response?.data);
            navigate("/");
        } catch (error) {
            toast.error("Error fetching terms and conditions");
        }
    };

    //..... to get terms..............
    const fetchTermsAndConditions = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}utico-tandc.txt`,
                { responseType: "text" }
            );
            setEditorContent(response.data);
        } catch (error) {
            toast.error("Error fetching terms and conditions");
        }
    };

    useEffect(() => {
        fetchTermsAndConditions();
    }, []);

    return (
        <div className="text-editor-container">
            <h5>
                <b>Terms & Policy</b>
            </h5>

            <ReactQuill
                value={editorContent}
                onChange={handleEditorChange}
                modules={TextEditor.modules}
                formats={TextEditor.formats}
            />
            <div className="form-group col-md-5" style={{ marginTop: "10px" }}>
                <button
                    type="button"
                    className="btn1"
                    style={{
                        margin: "5px",
                        alignItems: "right",
                        borderRadius: "2px",
                    }}
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    Cancel
                </button>
                <button
                    color="blue"
                    type="button"
                    // className="btn1"
                    style={{
                        margin: "5px",
                        alignItems: "right",
                        borderRadius: "2px",
                    }}
                    onClick={(e) => {
                        onSave(e);
                    }}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

// Quill modules and formats you want to use
TextEditor.modules = {
    toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
    ],
};

TextEditor.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
];

export default TextEditor;
