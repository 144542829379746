import React from "react";
import Logo from "../assets/logo.png";

function Header() {
    return (
        <header>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-sm-3">
                        <img src={Logo} className="img-fluid" alt="Logo" />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
