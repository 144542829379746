import React, { useEffect, useState } from "react";
import Close from "../assets/close.png";
import Arrow from "../assets/arrow.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

function PaymentFailedPage() {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const trans_id = searchParams.get("trans_id");
    const result = searchParams.get("result");

    const [paymentInprogress] = useState(
        localStorage.getItem("paymentInProgress")
    );

    const [email] = useState(localStorage.getItem("email"));
    const [amount] = useState(localStorage.getItem("amount"));
    const [acNo] = useState(localStorage.getItem("acNo"));
    const [mobile] = useState(localStorage.getItem("mobile"));

    // ............ send success mail.........

    const sendFailedMail = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}payment-gateway/send-failure-mail?email=${email}&amount=${amount}&acNo=${acNo}&transcactionId=${trans_id}&result=${result}&phone=${mobile}`
            );
        } catch (error) {
            console.error("Error fetching customer details:", error);
        } finally {
            // setLoading(false);
        }
    };

    // ....... to send failed mail .....

    useEffect(() => {
        email && acNo && amount && sendFailedMail();
    }, []);

    // ....... to scroll top .....

    useEffect(() => {
        window.scrollTo({ top: 80, behavior: "smooth" });
    }, []);

    //.............

    useEffect(() => {
        if (paymentInprogress === "true") {
            localStorage.removeItem("paymentInProgress");
            localStorage.removeItem("paymentType");
            localStorage.removeItem("mobile");
            localStorage.removeItem("email");
            localStorage.removeItem("amount");
            localStorage.removeItem("encryptData");
            localStorage.removeItem("acNo");
            localStorage.removeItem("outstandingBalance");
        } else {
            navigate("/");
        }
    }, []);

    return (
        <div>
            <section className="pay-fail">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="div1 text-center">
                                <img
                                    src={Close}
                                    className="img-fluid mb-4"
                                    alt="Close"
                                />
                                <h4>Payment Failed</h4>
                                <h5>
                                    <b>Oops! Something went wrong.</b>
                                </h5>
                                <p className="my-3">
                                    While trying to reserve money from your
                                    account
                                </p>
                                <a href="https://www.uticome.com/">
                                    <img
                                        src={Arrow}
                                        className="img-fluid"
                                        alt="Arrow"
                                    />{" "}
                                    Back To Home
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Scripts */}
            <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.3/dist/umd/popper.min.js"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        </div>
    );
}

export default PaymentFailedPage;
