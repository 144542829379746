import React, { useEffect, useState } from "react";

function TermsAndPolicy({ terms }) {
    return (
        <section className="terms-section2">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <h5>
                            <b>Terms & Policy</b>
                        </h5>
                        {terms &&
                            terms
                                ?.split("\n")
                                .map((line, index) => (
                                    <div key={index}>{line}</div>
                                ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TermsAndPolicy;
