import React from "react";

function Footer() {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <h4>
                                <b>UTICO FZC</b>
                            </h4>
                            <p>
                                P.O. Box: 54527
                                <br />
                                Al Hamra Industrial Zone (RAKEZ)
                                <br />
                                Al Jazeera Al Hamra
                                <br />
                                Ras Al Khaimah, U.A.E.
                            </p>
                            <a
                                href="https://www.uticome.com/brochure/"
                                color="#fff"
                            >
                                <button className="btn1 d-none d-sm-block">
                                    Brochure
                                </button>
                            </a>
                        </div>
                        <div className="col-sm-4">
                            <p>
                                T: 07-2434610/611
                                <br />
                                F: 07-2434612
                                <br />
                                E: info@uticome.com
                            </p>
                        </div>
                        <div className="col-sm-4">
                            <ul className="social-icons">
                                <li>
                                    <a
                                        href="https://www.instagram.com/uticofzc/"
                                        className="fa fa-instagram"
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.linkedin.com/company/uticofzc"
                                        className="fa fa-linkedin"
                                    ></a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-4">
                            <button className="btn1 d-block d-sm-none">
                                Brochure
                            </button>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="footer-sub text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                Copyright © 2023 Utico. All Rights Reserved. |
                                Disclaimer
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
