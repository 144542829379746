import React, { useEffect, useState } from "react";
import "../App.css"; // Assuming you have a CSS file to style your components
import axios from "axios";
import { toast } from "react-toastify";
import TermsAndPolicy from "./terms-and-policy";

function QuickPay() {
    const [customerDetails, setCustomerDetails] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [noRecord, setNoRecord] = useState(false);
    const [error, setError] = useState("");
    const [paymentDetails, setPaymentDetails] = useState({
        phone: "",
        email: null,
        paymentAmount: 0,
    });
    const [paymentDetailsErrors, setPaymentDetailsErrors] = useState({
        phone: "",
        email: "",
        paymentAmount: "",
    });

    const handleFormOnChange = (e) => {
        // setPaymentDetails((prevData) => ({
        //     ...prevData,
        //     [e.target.name]: e.target.value,
        // }));

        const { name, value } = e.target;

        // Prefix payment amount with "AED " if the field is paymentAmount
        if (name === "paymentAmount") {
            const formattedValue = value.startsWith("AED ")
                ? `AED ${value.slice(4).trim()}`
                : `AED ${value.trim()}`;
            setPaymentDetails({ ...paymentDetails, [name]: formattedValue });
            setPaymentDetailsErrors({ paymentAmount: "" });
        } else if (name === "phone") {
            const formattedPhone = value.startsWith("+971")
                ? value
                : `+971${value}`;
            setPaymentDetails({ ...paymentDetails, [name]: formattedPhone });
            setPaymentDetailsErrors({ phone: "" });
        } else {
            setPaymentDetails({ ...paymentDetails, [name]: value });
            setPaymentDetailsErrors({ email: "" });
        }
    };

    const validateEmail = (email) => {
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~"'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^\+971\d{9}$/;
        return phoneRegex.test(phone);
    };

    const validatePaymentAmount = (amount) => {
        const amountRegex = /^AED\s\d+(\.\d{1,2})?$/;
        return (
            amountRegex.test(amount) &&
            parseFloat(amount.replace("AED ", "")) > 0
        );
    };

    // ........... to store the terms...

    const [terms, setTerms] = useState();

    //............. validating search input field

    const validateSearchValue = (value) => {
        const regex = /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$/;
        if (!value) {
            return "Please Enter a Customer ID";
        } else if (value.length < 8 || value.length > 10) {
            return "Customer ID should be between 8 and 10 characters";
        }
        //  else if (!regex.test(value)) {
        //     return "Customer ID is incorrect";
        // }
        else {
            return "";
        }
    };

    // ...........  get customer details ................

    const fetchCustomerDetails = async (searchValue) => {
        localStorage.removeItem("encryptData");
        const validationError = validateSearchValue(searchValue);
        setError(validationError);
        if (validationError) {
            return;
        }
        // setLoading(true);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}payment-gateway/customer/outstanding-balance?customerId=${searchValue}`
            );
            const res = response.data;
            console.log("res", res);
            if (res && res?.length > 0) {
                setCustomerDetails(response.data);
                localStorage.setItem("encryptData", res[0]?.encryptData);
                setPaymentDetails({
                    phone: response.data[0]?.Customer_PhoneNum
                        ? `+971${response.data[0]?.Customer_PhoneNum}`
                        : "+971",
                    email: response.data[0]?.Customer_EMailAddress
                        ? response.data[0]?.Customer_EMailAddress
                        : "",
                    paymentAmount: response.data[0]?.Calculated_Balance
                        ? `AED ${response.data[0]?.Calculated_Balance}`
                        : "AED 0",
                });
                setNoRecord(false);
                setPaymentDetailsErrors({
                    phone: "",
                    email: "",
                    paymentAmount: "",
                });
            } else {
                setNoRecord(true);
            }
        } catch (error) {
            // console.error("Error fetching customer details:");
            // toast.error(error.response?.data?.message);
            setNoRecord(true);
        } finally {
            // setLoading(false);
        }
    };

    //  ..... payment submission ........

    const onPayNow = async (e) => {
        e.preventDefault();
        let formIsValid = true;
        let newErrors = { phone: "", email: "", paymentAmount: "" };

        // if (paymentDetails.email.length > 0) {
        if (!validateEmail(paymentDetails.email)) {
            newErrors.email = "Please enter a valid email address.";
            formIsValid = false;
        }
        // }
        if (paymentDetails.phone.length > 4) {
            if (!validatePhoneNumber(paymentDetails.phone)) {
                newErrors.phone =
                    "Please enter a valid phone number Or leave the field blank.";
                formIsValid = false;
            }
        }

        // Trim spaces for validation
        const trimmedPaymentAmount = paymentDetails.paymentAmount
            .replace(/\s+/g, " ")
            .trim();

        if (!validatePaymentAmount(trimmedPaymentAmount)) {
            newErrors.paymentAmount =
                "Please enter a valid payment amount greater than zero and up to two decimal places.";
            formIsValid = false;
        }

        // if (
        //     paymentDetails.phone.length != 13 &&
        //     paymentDetails.email.length == 0
        // ) {
        // if (!paymentDetails.email && paymentDetails.phone.length <= 4) {
        //     toast.error(
        //         "Please enter your email address or phone number to proceed with the payment."
        //     );
        //     formIsValid = false;
        // }
        // }

        setPaymentDetailsErrors(newErrors);
        if (formIsValid) {
            const formattedValue = paymentDetails?.paymentAmount?.replace(
                "AED ",
                ""
            );

            const amount_toDecimal = Number(formattedValue).toFixed(2);
            const data = {
                customerId: customerDetails[0]?.Customer_CustID,
                customerName: customerDetails[0]?.Customer_Name
                    ? customerDetails[0]?.Customer_Name
                    : "",
                customerEmail:
                    paymentDetails?.email?.length > 0
                        ? paymentDetails?.email
                        : null,
                customerPhone:
                    paymentDetails?.phone?.length > 4
                        ? paymentDetails?.phone.replace("+", "")
                        : null,
                amount: amount_toDecimal,
                // outStandingBalance:
                //     customerDetails[0]?.Calculated_Balance ,
                currency: "AED",
                description: customerDetails[0]?.Terms_Description,
                encryptData: localStorage.getItem("encryptData"),
            };

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}payment-gateway/checkout`,
                    data
                );

                console.log("response", response?.data?.paymentUrl);
                const redirectUrl = response?.data?.paymentUrl; // Adjust according to your response structure
                localStorage.setItem("paymentInProgress", "true");
                localStorage.setItem("paymentType", "Net Banking");
                localStorage.setItem(
                    "mobile",
                    paymentDetails?.phone.replace("+", "")
                );
                localStorage.setItem("email", paymentDetails?.email);
                localStorage.setItem(
                    "amount",
                    paymentDetails?.paymentAmount?.replace("AED ", "")
                );
                localStorage.setItem(
                    "acNo",
                    customerDetails[0]?.Customer_CustID
                        ? customerDetails[0]?.Customer_CustID
                        : ""
                );

                localStorage.setItem(
                    "outstandingBalance",
                    customerDetails[0]?.Calculated_Balance
                        ? customerDetails[0]?.Calculated_Balance
                        : ""
                );

                window.location.href = redirectUrl;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                toast.error("Something went wrong");
            } finally {
            }
        }
    };

    //..... to get terms..............
    const fetchTermsAndConditions = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}utico-tandc.txt`,
                { responseType: "text" }
            );
            setTerms(response.data);
            console.log(response?.data);
        } catch (error) {
            toast.error("Error fetching terms and conditions");
        }
    };

    useEffect(() => {
        fetchTermsAndConditions();
    }, []);

    console.log("paymentAmount", paymentDetails);

    return (
        <div>
            <section className="search-section1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="div1">
                                <h5 className="text-center py-3">
                                    <b>Quick Pay</b>
                                </h5>
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-10 box-shadow">
                                            <label htmlFor="search">
                                                Search Account
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="search"
                                                placeholder="Enter Account ID*"
                                                onChange={(e) => {
                                                    setSearchValue(
                                                        e.target.value
                                                    );
                                                    setCustomerDetails([]);
                                                    setNoRecord(false);
                                                    setError("");
                                                }}
                                            />
                                            {error && (
                                                <small
                                                    style={{ color: "yellow" }}
                                                >
                                                    {error}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-2">
                                            <button
                                                type="button"
                                                className="btn1"
                                                onClick={() => {
                                                    fetchCustomerDetails(
                                                        searchValue
                                                    );
                                                }}
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {noRecord ? (
                        <div className="col-sm-12">
                            <h6 className="blue-bg mb-3">
                                * This payment facility is available for Retail
                                Customers only *
                            </h6>
                        </div>
                    ) : (
                        customerDetails &&
                        customerDetails?.length > 0 && (
                            <>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h5 className="blue-bg mb-3">
                                            Customer Details
                                        </h5>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="mb-2">
                                            <label htmlFor="name">Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Name"
                                                value={
                                                    customerDetails[0]
                                                        ?.Customer_Name
                                                        ? customerDetails[0]
                                                              ?.Customer_Name
                                                        : ""
                                                }
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="mb-2">
                                            <label htmlFor="phone">
                                                Phone Number
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="phone"
                                                name="phone"
                                                placeholder="Phone Number"
                                                onChange={(e) =>
                                                    handleFormOnChange(e)
                                                }
                                                value={paymentDetails?.phone}
                                                required
                                            />
                                            {paymentDetailsErrors?.phone && (
                                                <small style={{ color: "red" }}>
                                                    {
                                                        paymentDetailsErrors?.phone
                                                    }
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="mb-2">
                                            <label htmlFor="email">
                                                Email Id
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Email Id"
                                                onChange={(e) =>
                                                    handleFormOnChange(e)
                                                }
                                                value={paymentDetails?.email}
                                                required
                                            />
                                            {paymentDetailsErrors?.email && (
                                                <small style={{ color: "red" }}>
                                                    {
                                                        paymentDetailsErrors?.email
                                                    }
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="mb-2">
                                            <label htmlFor="amt">
                                                Outstanding Amount
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="amount"
                                                placeholder="Outstanding Amount"
                                                value={
                                                    customerDetails[0]
                                                        ?.Calculated_Balance
                                                        ? `AED${" "}${customerDetails[0]?.Calculated_Balance?.toFixed(
                                                              2
                                                          )}`
                                                        : "AED 0"
                                                }
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="mb-2">
                                            <label htmlFor="amt">
                                                Payment Amount
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="paymentAmount"
                                                name="paymentAmount"
                                                placeholder="Payment Amount"
                                                onChange={(e) =>
                                                    handleFormOnChange(e)
                                                }
                                                value={
                                                    paymentDetails?.paymentAmount
                                                }
                                                required
                                            />
                                            {paymentDetailsErrors?.paymentAmount && (
                                                <small style={{ color: "red" }}>
                                                    {
                                                        paymentDetailsErrors?.paymentAmount
                                                    }
                                                </small>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <h5 className="blue-bg mt-3">
                                            Payment
                                        </h5>
                                        <table
                                            width="100%"
                                            className="mt-4 payment-table"
                                        >
                                            <thead>
                                                <tr className="bg-gray">
                                                    <th>Payment</th>
                                                    <th className="text-end">
                                                        Amount Details
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="border-bottom">
                                                    <td>Payment Amount</td>
                                                    <td className="text-end">
                                                        {
                                                            paymentDetails?.paymentAmount
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="text-end">
                                                        <span className="total">
                                                            Total
                                                        </span>{" "}
                                                        {
                                                            paymentDetails?.paymentAmount
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="float-end">
                                                        <button
                                                            className="pay-btn"
                                                            onClick={(e) => {
                                                                onPayNow(e);
                                                            }}
                                                        >
                                                            Pay Now
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-sm-12">
                                        <p
                                            style={{
                                                position: "relative",
                                                top: "85px",
                                            }}
                                        >
                                            *{" "}
                                            {customerDetails[0]
                                                ?.Terms_Description
                                                ? customerDetails[0]
                                                      ?.Terms_Description
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </div>
            </section>

            <TermsAndPolicy terms={terms} />
            {/* Scripts */}
            <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.3/dist/umd/popper.min.js"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        </div>
    );
}

export default QuickPay;
