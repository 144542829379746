import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useNavigate,
} from "react-router-dom";
import PaymentSuccess from "./page/paymentsuccesspage";
import PaymentFailed from "./page/paymentfailedpage"; // Assuming you have this component
import QuickPay from "./page/quickpay";
import Footer from "./page/footer";
import Header from "./page/header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "./page/edit-tems-and-policy";

function App() {
    return (
        <div>
            <Header />
            <Router>
                <Routes>
                    {/* <Switch> */}
                    <Route
                        path="/payment-success"
                        element={<PaymentSuccess />}
                    />
                    <Route path="/payment-failed" element={<PaymentFailed />} />
                    <Route path="/" element={<QuickPay />} />{" "}
                    {/* <Route
                        path="/edit-utico-terms-and-policies"
                        element={<TextEditor />}
                    />{" "} */}
                    {/* <Route path="*" element={<QuickPay />} /> */}
                </Routes>
            </Router>
            <ToastContainer />
            <Footer />
        </div>
    );
}

export default App;
